<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="typegrid"
          title="기계분류"
          tableId="typegrid"
          :columns="typegrid.columns"
          :data="typegrid.data"
          @rowClick="rowClick"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
        >
          <template v-slot:suffixTitle>
            <font v-if="editable" style="font-size:0.8em;font-weight:300;">
              &nbsp;&nbsp;
              [ 행 클릭하세요 ] &nbsp;&nbsp;
            </font>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="inspGrid"
          title="기계분류별 안전검사 검사대상/필수항목"
          topBorderClass="topcolor-lightblue"
          tableId="inspGrid"
          :columns="inspGrid.columns"
          :data="inspGrid.data"
          :editable="editable"
          :usePaging="false"
          :hideBottom="true"
          :filtering="false"
          :columnSetting="false"
          :isExcelDown="false"
          :isFullScreen="false"
          selection="multiple"
          rowKey="hazardousMachineryInspectionTargetId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addRow1" v-if="editable && isSelectTgt" />
              <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeRow1" v-if="editable && isSelectTgt" />
              <c-btn label="LBLSAVE" icon="save" @btnClicked="saveRow1" v-if="editable && isSelectTgt" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-equipment-item',
  data() {
    return {
      editable: true,
      searchParam: {
        plantCd: null,
      },
      isSelectTgt: false,
      isSelectDetail: false,
      selectedGroupCd: '',
      detailKey: '',
      list1Url: '',
      save1Url: '',
      delete1Url: '',
      list2Url: '',
      save2Url: '',
      insert2Url: '',
      update2Url: '',
      delete2Url: '',
      isSave2: false,
      saveType2: 'POST',
      data: {
        itemName: '',
        testMothod: '',
        itemCriterion: '',
        useEquip: '',
        hazardousMachineryInspectionTargetId: '',
        hazardousMachineryInspectionItemId: '',
      },
      typegrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '기계분류',
            align: 'left',
            sortable: false,
          }
        ],
        data: [],
      },
      inspGrid: {
        columns: [],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.save2Url = transactionConfig.sop.hhm.hazard.inspection.item.insert.url;
      this.list1Url = selectConfig.sop.hhm.hazard.inspection.target.list.url;
      this.save1Url = transactionConfig.sop.hhm.hazard.inspection.target.save.url;
      this.delete1Url = transactionConfig.sop.hhm.hazard.inspection.target.delete.url;
      this.list2Url = selectConfig.sop.hhm.hazard.inspection.item.list.url;
      this.insert2Url = transactionConfig.sop.hhm.hazard.inspection.item.insert.url;
      this.update2Url = transactionConfig.sop.hhm.hazard.inspection.item.update.url;
      this.delete2Url = transactionConfig.sop.hhm.hazard.inspection.item.delete.url;

      
      this.$comm.getComboItems('HHM_TEST_METHOD').then(_result => {
        this.inspGrid.columns = [
          {
            required: true,
            name: 'sortOrder',
            field: 'sortOrder',
            label: '조항',
            align: 'center',
            type: 'number',
            style: 'width:60px',
            sortable: false,
          },
          {
            required: true,
            name: 'itemName',
            field: 'itemName',
            label: '검사항목',
            align: 'left',
            type: 'text',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'testMothod',
            field: 'testMothod',
            label: '검사방법',
            align: 'left',
            sortable: false,
            style: 'width:100px',
            type: 'multiSelect',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'itemCriterion',
            field: 'itemCriterion',
            label: '판정기준',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useEquip',
            field: 'useEquip',
            label: '활용장비',
            align: 'left',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },
        ]

      });
      this.getTypeList();
    },
    rowClick(row) {
      this.isSelectTgt = true;
      this.selectedGroupCd = row.code;
      this.getList1(row);
      
      this.$set(this.data, 'itemName', '')
      this.$set(this.data, 'testMothod', '')
      this.$set(this.data, 'itemCriterion', '')
      this.$set(this.data, 'useEquip', '')
      this.$set(this.data, 'hazardousMachineryInspectionTargetId', '')
      this.$set(this.data, 'hazardousMachineryInspectionItemId', '')
      this.isSelectDetail = false;
    },
    getTypeList() {
      this.rowRemoveSelect();
      this.isSelectTgt = false;
      this.inspGrid.data = [];
      this.$comm.getComboItems('HHM_TYPE_CD').then(_result => {
        this.typegrid.data = _result;
      });
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
      this.selectedGroupCd = '';
    },
    getList1(row) {
      this.$http.url = this.list1Url;
      this.$http.type = 'GET';
      this.$http.param = {
        machineryClassification: row.code,
      }
      this.$http.request((_result) => {
        this.inspGrid.data = _result.data;
      },
      () => {
      });
    },
    addRow1() {
      this.inspGrid.data.splice(0,0,{
        editFlag: 'C',
        hazardousMachineryInspectionTargetId: uid(),  // 검사대상-검사항목 일련번호
        machineryClassification: this.selectedGroupCd,  // 기계분류
        itemName: '',  // 검사대상-검사항목명
        sortOrder: this.inspGrid.data.length === 0 ? 1 : parseInt(this.inspGrid.data[this.inspGrid.data.length-1].sortOrder) + 1,  // 순번
      })
    },
    saveRow1() {
      if (this.$comm.validTable(this.inspGrid.columns, this.inspGrid.data)) {
        let saveData = this.inspGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
          if (Array.isArray(item.testMothod)) {
            item.testMothod = item.testMothod.join(',');
          }
        });
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.save1Url;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList1({code: this.selectedGroupCd});
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeRow1() {
      let selectData = this.$refs['inspGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete1Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getItemList({code: selectData[0].codeGrpCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.inspGrid.data = this.$_.reject(this.inspGrid.data, item);
              })
              this.$refs['inspGrid'].$refs['compo-table'].clearSelection();
              
              this.$set(this.data, 'itemName', '')
              this.$set(this.data, 'testMothod', '')
              this.$set(this.data, 'itemCriterion', '')
              this.$set(this.data, 'useEquip', '')
              this.$set(this.data, 'hazardousMachineryInspectionTargetId', '')
              this.$set(this.data, 'hazardousMachineryInspectionItemId', '')
              this.isSelectDetail = false;
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    rowClick2(row) {
      this.isSelectDetail = true;
      this.getList2(row);
    },
    getList2(row) {
      this.$http.url = this.list2Url;
      this.$http.type = 'GET';
      this.$http.param = {
        hazardousMachineryInspectionTargetId: row.hazardousMachineryInspectionTargetId,
      }
      this.$http.request((_result) => {
        this.data = _result.data; 

        if (!this.data.hazardousMachineryInspectionTargetId) {
          this.$set(this.data, 'itemName', row.itemName + ' / ' + row.sortOrder)
          this.$set(this.data, 'hazardousMachineryInspectionTargetId', row.hazardousMachineryInspectionTargetId)
        }
      },
      () => {
      });
    },
    saveDetail() {
      if (this.data.hazardousMachineryInspectionItemId) {
        this.save2Url = this.update2Url;
        this.saveType2 = 'PUT';
      } else {
        this.save2Url = this.insert2Url;
        this.saveType2 = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.chgUserId = this.$store.getters.user.userId
              this.data.regUserId = this.$store.getters.user.userId

              this.isSave2 = !this.isSave2;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList2({
        hazardousMachineryInspectionTargetId: _result.data
      })
    },
  }
};
</script>
